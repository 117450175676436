import React from "react"

import "../styles/error.scss"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Zopim from "../components/shared/zopim";
import M6 from "../components/shared/m6";


function NotFoundPage(){
    return (
        <Layout>
            <Zopim />
            <SEO title="Home" />
            <main role="main">
                <div className="error-template">
                    <h1 className="headline">Oops!</h1>
                    <h2 className="headline-1">404 Not Found</h2>
                    <div className="error-details">
                        Sorry, an error has occurred, Requested page not found!
                    </div>
                    <div className="error-actions">
                        <a href="http://www.jquery2dotnet.com" className="button-primary d-inline-block mt-3">
                            Take Me Home
                        </a>
                    </div>
                </div>
            </main>
            <footer>
                <M6/>
            </footer>
        </Layout>
    )
}

export default NotFoundPage
